<template>
    <div class="header-options">
        <labelc v-if="showOkEmployee !== ''" type="block border-bottom" :text="'Mitarbeiter ohne Fehler'" :icon-left="{ styleIcon: 'width: 20px; color: var(--col-ok)', icon: showOkEmployee ? 'fa-user' : 'fa-user-slash'}"  @click="showOkEmployee=!showOkEmployee,toggleShowOkEmployee()"/>
        <labelc v-if="showEmployeeWithInfo !== ''" type="block border-bottom" :text="'Mitarbeiter mit Hinweisen'" :icon-left="{ styleIcon: 'width: 20px; color: var(--col-info)', icon: showEmployeeWithInfo ? 'fa-user' : 'fa-user-slash'}"  @click="showEmployeeWithInfo=!showEmployeeWithInfo,toggleShowEmployeeWithInfo()"/>
        <labelc v-if="showEmployeeWithWarningOrDanger !== ''" type="block border-bottom" :text="'Mitarbeiter mit Warnungen'" :icon-left="{ styleIcon: 'width: 20px; color: var(--col-danger)', icon: showEmployeeWithWarningOrDanger ? 'fa-user' : 'fa-user-slash'}"  @click="showEmployeeWithWarningOrDanger=!showEmployeeWithWarningOrDanger,toggleShowEmployeeWithWarningOrDanger()"/>
        <labelc v-if="showInactiveEmployees !== ''" type="block border-bottom" :text="'Deaktivierte Mitarbeiter'" :icon-left="{ styleIcon: 'width: 20px; ', icon: showInactiveEmployees ? 'fa-user' : 'fa-user-slash'}"  @click="showInactiveEmployees=!showInactiveEmployees,toggleShowInactiveEmployees()"/>
    </div>
</template>

<script>
    export default {
        name: "employeedisplayoptions",
        data(){
            return{
                pictureUrl: '',
                name: '',
                showOkEmployee: true,
                toggleShowOkEmployee: () => {},
                showEmployeeWithInfo: true,
                toggleShowEmployeeWithInfo: () => {},
                showEmployeeWithWarningOrDanger: true,
                toggleShowEmployeeWithWarningOrDanger: () => {},
                showInactiveEmployees: false,
                toggleShowInactiveEmployees: () => {},
                closedCallback: () => {}
            }
        },
        computed:{
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props:{
            state: Object,
        },
        methods:{
            Close(){
                this.closedCallback();
                this.$parent.Toggle();
            },
        }
    }
</script>

<style scoped>
    .header-options{
        width: 280px;
    }
</style>